<template>
  <div class="wrap-top-search-bar d-flex align-items-center">
    <img
      style="height: 25px"
      class="cursor-pointer"
      :src="require('@/assets/images/app-icons/map.png')"
      @click="showAddressSelection"
    >
    <GmapAutocomplete
      ref="mapAutoComplete"
      :value="userCurrentLocation"
      type="text"
      class="form-control border-0 shadow-none"
      @input="userCurrentLocation = $event.target.value"
      @place_changed="setPlace"
    />
    <img
      v-if="$route.meta.showPin"
      style="height: 25px"
      class="cursor-pointer"
      :src="require('@/assets/images/app-icons/location.png')"
      @click="selectCurrentLocation"
    >
    <feather-icon
      v-if="userCurrentLocation"
      icon="XIcon"
      size="21"
      class="cursor-pointer"
      @click="resetSearch"
    />
  </div>
</template>

<script>
import eventBus from '@/libs/event-bus'

export default {
  data() {
    return {
      userCurrentLocation: '',
    }
  },
  mounted() {
    eventBus.$on('location-bar-componet-set', payload => {
      if (payload) {
        if (payload.type === 'userCurrentLocation') {
          this.userCurrentLocation = payload.data
        }
      }
    })
  },
  destroyed() {
    eventBus.$off('location-bar-componet-set')
  },
  methods: {
    showAddressSelection() {
      eventBus.$emit('location-bar-component', {
        type: 'showAddressSelection',
      })
    },
    setPlace(payload) {
      eventBus.$emit('location-bar-component', {
        type: 'setPlace',
        data: payload,
      })
    },
    selectCurrentLocation() {
      eventBus.$emit('location-bar-component', {
        type: 'selectCurrentLocation',
      })
    },
    resetSearch() {
      eventBus.$emit('location-bar-component', {
        type: 'resetSearch',
      })
    },
  },
}
</script>
